import React, { useContext, useMemo, useState } from 'react'

const AppContext = React.createContext({
  date: null,
  setDate: () => {},
  tabIndex: null,
  setTabIndex: () => {},
})
export const useAppContext = () => useContext(AppContext)
export const AppContextProvider = ({ children }) => {
  const [date, setDate] = useState(Date.now())
  const [tabIndex, setTabIndex] = useState(0)
  const [removeGmail, setRemoveGmail] = useState(false)
  const [removeAserto, setRemoveAserto] = useState(false)
  const [removeUsersWithNoTenants, setRemoveUsersWithNoTenants] = useState(false)
  const setDateWrapper = (newValue) => {
    setDate(newValue)
    setTabIndex(0)
  }

  const value = useMemo(
    () => ({
      date,
      setDate: setDateWrapper,
      tabIndex,
      setTabIndex,
      removeGmail,
      setRemoveGmail,
      removeAserto,
      setRemoveAserto,
      removeUsersWithNoTenants,
      setRemoveUsersWithNoTenants
    }),
    [date, tabIndex, removeGmail, removeAserto, removeUsersWithNoTenants]
  )

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
