import React from 'react'
import { Button } from '@mui/material'
import { useLogin } from 'react-admin'

const LoginButton = () => {
  const login = useLogin()
  const isAuthenticated = false

  const handleLogin = () => {
    login({}) // Do not provide code, just trigger the redirection
  }

  if (!isAuthenticated) {
    return <Button onClick={handleLogin}>Log In</Button>
  }
}

export default LoginButton
