import {
  ArrayField,
  ChipField,
  Datagrid,
  List,
  Show,
  SimpleShowLayout,
  Edit,
  SimpleForm,
  TextInput,
  FunctionField,
  useRecordContext,
} from 'react-admin'
import { DateField } from '../components/DateFormatter'
import ChipLink from '../components/ChipLink'

import { Chip } from '@mui/material'
import { Link } from 'react-router-dom'

export const AccountList = () => (
  <List>
    <Datagrid rowClick="show">
      <ChipField source="id" />
      <FunctionField
        label="User"
        render={() => <ChipLink resource="users" idSource="id" labelSource="id" />}
      />
      <ArrayField label="Tenants" source="tenantsList">
        <Datagrid bulkActionButtons={false}>
          <RenderTenantsChipLink></RenderTenantsChipLink>
        </Datagrid>
      </ArrayField>
      <FunctionField
        label="Default tenant"
        render={() => (
          <ChipLink resource="tenants" idSource="defaultTenant" labelSource="defaultTenant" />
        )}
      />
      <DateField source="registrationDate" />
    </Datagrid>
  </List>
)

export const AccountShow = () => (
  <Show>
    <SimpleShowLayout>
      <FunctionField
        label="User"
        render={() => <ChipLink resource="users" idSource="id" labelSource="id" />}
      />
      <ArrayField label="Tenants" source="tenantsList">
        <Datagrid bulkActionButtons={false}>
          <RenderTenantsChipLink></RenderTenantsChipLink>
        </Datagrid>
      </ArrayField>
      <FunctionField
        label="Default tenant"
        render={() => (
          <ChipLink resource="tenants" idSource="defaultTenant" labelSource="defaultTenant" />
        )}
      />
      <DateField source="registrationDate" />
    </SimpleShowLayout>
  </Show>
)

export const AccountEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="id" />
    </SimpleForm>
  </Edit>
)

const RenderTenantsChipLink = () => {
  const record = useRecordContext()
  return (
    <Link to={`/tenants/${record.id}/show`}>
      <Chip label={record.id} />
    </Link>
  )
}

