import {
  ArrayInput,
  BooleanField,
  Datagrid,
  EmailField,
  List,
  TextField,
  ChipField,
  Show,
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SimpleShowLayout,
  FunctionField,
  ArrayField,
  WrapperField,
  SimpleFormIterator,
  DateField,
} from 'react-admin'

import { Chip } from '@mui/material'
import { Link } from 'react-router-dom'

import { useRecordContext } from 'react-admin'
import QuickFilter from '../components/QuickFilter'
import HubspotField from '../components/HubspotField'

const Pic = ({ source }) => {
  const record = useRecordContext()
  const url = record[source]
  return url ? <img width="50" height="50" src={url} alt={url} /> : null
}

const userFilters = [
  <TextInput label="Display Name" source="displayName" alwaysOn />,
  <QuickFilter source="removeAserto" label="Remove Aserto emails" defaultValue={'!aserto'} />,
  <QuickFilter source="removeGmail" label="Remove gmails" defaultValue={'!gmail'} />,
  <QuickFilter source="removeNoTenants" label="Remove users with no tenants" defaultValue={'!0'} />,
  <TextInput source="email" label="email" />,
]

export const UserList = () => (
  <List filters={userFilters}>
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <Pic source="picture" sortable={false} />
      <TextField source="displayName" />
      <EmailField source="properties.email" label="Email" />
      <ChipField source="id" />
      <DateField source="createdAt" label="Created At" />
      <FunctionField
        label="Tenants"
        sortBy="tenants"
        render={(u) => u.tenants?.length}
      />
      <BooleanField source="enabled" sortable={false} />
      <BooleanField source="deleted" sortable={false}  />
    </Datagrid>
  </List>
)

const UserTitle = () => {
  const record = useRecordContext()
  return <span>User {record ? `"${record.displayName}"` : ''}</span>
}

const Identities = () => (
  <Datagrid bulkActionButtons={false}>
    <FunctionField render={(r) => r && r['id']} />
  </Datagrid>
)

const TenantLink = ({ id }) => (
  <Link to={`/tenants/${id}/show`}>
    <Chip label={id} />
  </Link>
)

const Tenants = () => {

  return (
    <Datagrid bulkActionButtons={false}>
      <FunctionField
        label="Tenant ID"
        render={(r) => (r  && r.id ? <TenantLink id={r.id} /> : null)}
      />
      <FunctionField
        label="Role"
        render={(r) => {
          return r  && r.role
        }}
      />
      <FunctionField label="Personal" render={(r) => { return r && r.personal.toString() }} />
    </Datagrid>
  )
}

export const UserShow = () => (
  <Show title={<UserTitle />}>
    <SimpleShowLayout>
      <ChipField source="id" />
      <BooleanField source="enabled" />
      <BooleanField source="deleted" />
      <TextField source="displayName" />
      <EmailField source="email" />
      <Pic source="picture" />
      <WrapperField label="Link to Hubspot record">
        <HubspotField />
      </WrapperField>
      <ArrayField label="Identities" source="identities">
        <Identities />
      </ArrayField>
      <ArrayField label="Tenants" source="tenants">
        <Tenants />
      </ArrayField>
      <DateField source="createdAt" label="Created At" />
    </SimpleShowLayout>
  </Show>
)

const FieldLabel = (index) => {
  const record = useRecordContext()
  return (
    record.featureFlags &&
    record.featureFlags[index] && (
      <div style={{ minWidth: 200 }}>{record.featureFlags[index].name}</div>
    )
  )
}

const transform = (data) => {
  const featureFlags = {}
  for (const flag of data.featureFlags) {
    featureFlags[flag.name] = flag.value
  }
  return featureFlags
}

export const UserEdit = () => (
  <Edit title={<UserTitle />} transform={transform}>
    <SimpleForm>
      <ArrayInput label="Feature Flags" source="featureFlags">
        <SimpleFormIterator getItemLabel={FieldLabel} disableAdd disableRemove disableReordering>
          <BooleanInput source="value" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
)
