import React from 'react'
import { useRecordContext } from 'react-admin'

const DateFormatter = ({ value }) => {
  const date = new Date(value)

  return value ? (
    <span>{date ? `${date.toLocaleDateString()} ${date.toLocaleTimeString('it-IT')}` : ''}</span>
  ) : null
}

export const DateField = ({ source }) => {
  const record = useRecordContext()
  const segments = source.split('.')

  let val = null
  for (const seg of segments) {
    val = val ? val[seg] : record[seg]
  }
  return <DateFormatter value={val} />
}

export default DateFormatter
