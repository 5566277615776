import { useRecordContext } from 'react-admin'
import { Link } from '@mui/material'

const HubspotField = () => {
  const record = useRecordContext()
  return (
    <Link href={`https://app.hubspot.com/contacts/21300286/contact/${record.email}`} target="_blank">
      {record.email}
    </Link>
  )
}

export default HubspotField
