import React from 'react'
import { Card, CardContent, CardHeader } from '@mui/material'
import LoginButton from '../components/LoginButton'
import { Title, useGetList } from 'react-admin'
import UserReport from '../components/UserReport'
import { useAppContext } from '../utils/appContext'
import authConfig from '../utils/authConfig'
import { ArrowLeft, ArrowRight, RemoveCircle, AddCircle, Today } from '@mui/icons-material'
import Button from '../components/Button'
import { getToken } from '../utils/token'

const Dashboard = () => {
  const {
    date,
    setDate,
    removeGmail,
    setRemoveGmail,
    removeAserto,
    setRemoveAserto,
    removeUsersWithNoTenants,
    setRemoveUsersWithNoTenants,
  } = useAppContext()

  const jwt = getToken()
  const token = jwt && jwt.id_token
  const offset = 60 * 60 * 24 * 28 * 1000 // 28 days

  const { data, isLoading, error } = useGetList('users', {
    sort: { field: 'createdAt', order: 'DESC' },
    pagination: { page: 1, perPage: 10000 },
  }, {
    retry: 0
  })

  if (isLoading) {
    return (
      <Card>
        <CardContent>Loading...</CardContent>
      </Card>
    )
  }

  if (error) {
    return (
      <Card>
        <CardContent>You are not authorized to view the dashboard.</CardContent>
      </Card>
    )
  }

  let users =
    data &&
    data.map((u) => ({
      id: u.id,
      name: u.displayName,
      email: u.email,
      created: u.createdAt,
      tenants:
        u.tenants && u.tenants.length
          ? u.tenants.map((a) => (a && a.length === 2 ? a[0] : null))
          : null,
    }))

  if (removeAserto) {
    users = users.filter((u) => !u.email.includes('aserto'))
  }
  if (removeGmail) {
    users = users.filter((u) => !u.email.includes('gmail'))
  }
  if (removeUsersWithNoTenants) {
    users = users.filter((u) => u.tenants)
  }

  const next = () => setDate(date - offset)
  const prev = () => setDate(date + offset < Date.now() ? date + offset : Date.now())
  const current = () => setDate(Date.now())

  const update = async () => {
    const url = `${authConfig.serviceUrl}/api/v1/cache`
    await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  return (
    <Card>
      <Title title="Dashboard" />
      <CardHeader title="Welcome to the Aserto production data browser" />
      <CardContent>You can browse users, accounts, and tenants.</CardContent>

      <Card sx={{ borderBottom: 2, borderColor: 'divider' }}>
        <CardContent>
          <Button label="Update Data" onClick={update} />
        </CardContent>
      </Card>

      <Card sx={{ borderBottom: 2, borderColor: 'divider' }}>
        <CardHeader title={`Total number of users: ${users && users.length}`} />
        <CardContent>
          <Button
            label={removeAserto ? 'Include Aserto' : 'Remove Aserto'}
            onClick={() => setRemoveAserto(!removeAserto)}
          >
            {removeAserto ? <AddCircle /> : <RemoveCircle />}
          </Button>
          <Button
            label={removeGmail ? 'Include gmail' : 'Remove gmail'}
            onClick={() => setRemoveGmail(!removeGmail)}
          >
            {removeGmail ? <AddCircle /> : <RemoveCircle />}
          </Button>
          <Button
            label={removeUsersWithNoTenants ? 'Include users with no tenants' : 'Remove users with no tenants'}
            onClick={() => setRemoveUsersWithNoTenants(!removeUsersWithNoTenants)}
          >
            {removeUsersWithNoTenants ? <AddCircle /> : <RemoveCircle />}
          </Button>
        </CardContent>
      </Card>

      <Card>
        <CardHeader title="Weekly User Report" />
        <CardContent>
          <Button label="Latest" onClick={current}>
            <Today />
          </Button>
          <Button label="More Recent" onClick={prev}>
            <ArrowLeft />
          </Button>
          <Button label="Less Recent" alignIcon="right" onClick={next}>
            <ArrowRight />
          </Button>
        </CardContent>
      </Card>

      <UserReport users={users} date={date} />

      <CardContent>
        <LoginButton />
      </CardContent>
    </Card>
  )
}

export default Dashboard
