import {
  Datagrid,
  List,
  TextField,
  ChipField,
  Show,
  Edit,
  SimpleForm,
  TextInput,
  SimpleShowLayout,
  FunctionField,
} from 'react-admin'

import { useRecordContext } from 'react-admin'

export const PolicyList = () => (
  <List>
    <Datagrid rowClick="show">
      <ChipField source="id" />
      <TextField source="name" />
      <FunctionField
        label="Image"
        sortBy="registry_org"
        render={(record) => `${record.registry_org}/${record.registry_image}:${record.registry_tag}`}
      />
    </Datagrid>
  </List>
)

const PolicyTitle = () => {
  const record = useRecordContext()
  return <span>Poicy {record ? `"${record.name}"` : ''}</span>
}

export const PolicyShow = () => (
  <Show title={<PolicyTitle />}>
    <SimpleShowLayout>
      <ChipField source="id" />
      <TextField source="name" />
      <FunctionField
        label="Image"
        sortBy="registry_org"
        render={(record) => `${record.registry_org}/${record.registry_image}:${record.registry_tag}`}
      />
    </SimpleShowLayout>
  </Show>
)

export const PolicyEdit = () => (
  <Edit title={<PolicyTitle />}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
)
