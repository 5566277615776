export const getToken = () => {
  const tokenJson = localStorage.getItem('token')
  const token = JSON.parse(tokenJson)
  return token
}

export const getProfileFromToken = (token) => {
  const jwt = JSON.parse(atob(token.id_token.split('.')[1]))
  return { id: 'my-profile', ...jwt }
}
