import { useRecordContext } from 'react-admin'

import { Chip } from '@mui/material'
import { Link } from 'react-router-dom'

const ChipLink = ({ idSource, labelSource, resource }) => {
  const record = useRecordContext()
  return (
    <Link
      to={`/${resource}/${record[resource]?.[idSource]}/show`}
      style={{ textDecoration: 'underline 1px #277f82' }}
    >
      <Chip label={record[resource]?.[labelSource]} />
    </Link>
  )
}

export default ChipLink
