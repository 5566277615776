import { defaultTheme } from 'react-admin'

const lochivar = {
  50: '#141d1d',
  100: '#141d1d',
  200: '#162828',
  300: '#183334',
  400: '#1a3e3f',
  500: '#1c484a',
  600: '#1f5355',
  700: '#215e60',
  800: '#23696c',
  900: '#257477',
  A100: '#277f82',
  A200: '#2f989c',
  A400: '#277f82',
  A700: '#67cbcf',
}

const theme = {
  ...defaultTheme,
  palette: {
    primary: lochivar,
    secondary: lochivar,
  },
}

export default theme
