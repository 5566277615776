import React from 'react'
import { Card, CardContent, Tabs, Tab, Box } from '@mui/material'
import { FunctionField, Datagrid, TextField, WrapperField, DateField } from 'react-admin'
import HubspotField from './HubspotField'
import { useAppContext } from '../utils/appContext'
import { Chip } from '@mui/material'
import { Link } from 'react-router-dom'


import { useRecordContext } from 'react-admin'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

const UserReport = ({ users, date }) => {
  const { tabIndex: value, setTabIndex: setValue } = useAppContext()

  var dateOffset = 24 * 60 * 60 * 1000 * 7 // 7 days
  const weeks = [1, 2, 3, 4].map((n) => {
    const lastWeek = new Date().setTime(date - dateOffset)
    const usersForWeek = users.filter((u) => Date.parse(u.created) > lastWeek && Date.parse(u.created) < date)
    const label = `${new Date(lastWeek).toLocaleDateString()} - ${new Date(
      date
    ).toLocaleDateString()}`
    date = date - dateOffset
    return {
      label,
      total: usersForWeek.length,
      users: usersForWeek,
    }
  })

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          {weeks.map((w) => (
            <Tab label={w.label} key={w.label} />
          ))}
        </Tabs>
      </Box>
      {weeks.map((w, i) => (
        <TabPanel value={value} index={i} key={i}>
          <Card>
            <CardContent>{`New users: ${w.total}`}</CardContent>
            <Datagrid
              bulkActionButtons={false}
              data={w.users}
              sort={{
                field: 'created',
                order: 'DESC',
              }}
            >
              <FunctionField
                label="User"
                render={() => <RenderChipLink/>}
              />
              <TextField source="name" />
              <WrapperField label="Email (link to Hubspot record)">
                <HubspotField />
              </WrapperField>
              <DateField source="created" label="Created At" />
              <FunctionField label="Tenants" render={(r) => `${r.tenants?.length ?? 0}`} />
            </Datagrid>
          </Card>
        </TabPanel>
      ))}
    </>
  )
}

const RenderChipLink = () => {
  const record = useRecordContext()
  return (
    <Link to={`/users/${record.id}/show`}>
      <Chip label={record.id} />
    </Link>
  )
}

export default UserReport
