import React from 'react'
import { Card, CardContent, CardHeader } from '@mui/material'
import { Title } from 'react-admin'
import { getToken, getProfileFromToken } from '../utils/token'

const Pic = ({ url }) => {
  return url ? (
    <img style={{ borderRadius: '50%' }} width="100" height="100" src={url} alt={url} />
  ) : null
}

const Profile = () => {
  const token = getToken()
  const user = token && getProfileFromToken(token)
  return !token ? null : (
    <Card>
      <Title title="Profile" />
      <CardHeader title={user.name} />
      <CardContent>{user.email}</CardContent>
      <CardContent>
        <Pic url={user.picture} />
      </CardContent>
      <CardContent>
        <pre style={{ background: '#eeeeee', padding: 10 }}>{JSON.stringify(user, null, 2)}</pre>
      </CardContent>
    </Card>
  )
}

export default Profile
