import React, { forwardRef } from 'react'
import { Logout, UserMenu, MenuItemLink, useUserMenu } from 'react-admin'
import { Settings as SettingsIcon } from '@mui/icons-material'

const ProfileMenuItem = forwardRef((props, ref) => {
  const { onClose } = useUserMenu()
  return (
    <MenuItemLink
      {...props}
      ref={ref}
      to="/profile"
      primaryText="Profile"
      leftIcon={<SettingsIcon />}
      onClick={onClose}
    />
  )
})

const MyUserMenu = () => (
  <UserMenu>
    <ProfileMenuItem />
    <Logout />
  </UserMenu>
)

export default MyUserMenu
