import {
  ArrayField,
  BooleanField,
  Datagrid,
  List,
  TextField,
  ChipField,
  Show,
  Edit,
  SimpleForm,
  TextInput,
  SingleFieldList,
  NumberField,
  SimpleShowLayout,
  FunctionField,
  ReferenceManyField,
  ReferenceOneField,
  useRefresh,
} from 'react-admin'

import { Chip } from '@mui/material'
import { Link } from 'react-router-dom'

import QuickFilter from '../components/QuickFilter'

import { useRecordContext } from 'react-admin'

import { useEffect} from 'react'

const tenantFilters = [
  <TextInput label="Tenant Name" source="name" alwaysOn />,
  <QuickFilter source="personal" label="Organizations" defaultValue={false} />,
]

export const TenantList = () => (
  <List filters={tenantFilters}>
    <Datagrid rowClick="show">
      <ChipField source="id" />
      <TextField source="name" />
      <FunctionField
        label="# Members"
        sortBy="membersList"
        render={(record) => `${record.membersList.length}`}
      />
      <FunctionField
        sortBy="connectionsList"
        label="# Connections"
        render={(record) =>
          `${record.connectionsList.filter((c) => !c.description.includes('Aserto')).length}`
        }
      />
      <ArrayField source="connectionsList">
        <SingleFieldList>
          <FunctionField
            label="Connections"
            render={(record) =>
              record.description.includes('Aserto') ? null : <Chip label={record.description} />
            }
          />
        </SingleFieldList>
      </ArrayField>
      <BooleanField source="personal" />
      <NumberField source="maxHostedInstances" />
    </Datagrid>
  </List>
)

const TenantTitle = () => {
  const record = useRecordContext()
  return <span>Tenant {record ? `"${record.name}"` : ''}</span>
}

const RenderChipLink = () => {
  const record = useRecordContext()
  return (
    <Link to={`/accounts/${record.id}/show`}>
      <Chip label={record.id} />
    </Link>
  )
}

export const TenantShow = () => {
  const refresh = useRefresh()
  useEffect(() => {
    refresh()
  }, [refresh])
  return (
  <Show title={<TenantTitle />}>
    <SimpleShowLayout>
      <ChipField source="id" />
      <TextField source="name" />
      <ArrayField source="membersList" label="Members">
        <Datagrid bulkActionButtons={false}>
          <RenderChipLink />
        </Datagrid>
      </ArrayField>
      <ArrayField source="connectionsList">
        <Datagrid bulkActionButtons={false}>
          <TextField source="name" />
          <TextField source="description" />
        </Datagrid>
      </ArrayField>
      <BooleanField source="personal" />
      <NumberField source="maxHostedInstances" />

      <ReferenceManyField reference="policies" target="id" label="Policies">
        <Datagrid bulkActionButtons={false}>
          <ChipField source="id" />
          <TextField source="name" />
          <FunctionField
            label="Image"
            sortBy="registry_org"
            render={(record) =>
              `${record.registry_org}/${record.registry_image}:${record.registry_tag}`
            }
          />
        </Datagrid>
      </ReferenceManyField>

      <ReferenceOneField source="id" reference="directory" target="id" label="Directory Size">
        <NumberField source="size" />
      </ReferenceOneField>

      <ReferenceOneField source="id" reference="directory" target="id" label="User Count">
        <NumberField source="users" />
      </ReferenceOneField>
    </SimpleShowLayout>
  </Show>

)}

export const TenantEdit = () => (
  <Edit title={<TenantTitle />}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
)
