import React from 'react'
import { styled } from '@mui/material/styles'
import { Button, CardContent } from '@mui/material'
import { Form, useTranslate } from 'ra-core'
import { useLogin } from 'react-admin'

export const LoginForm = (props) => {
  const { className } = props
  const translate = useTranslate()

  const login = useLogin()
  const handleLogin = () => {
    login({}) // Do not provide code, just trigger the redirection
  }

  return (
    <StyledForm mode="onChange" noValidate className={className}>
      <CardContent className={LoginFormClasses.content}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={LoginFormClasses.button}
          onClick={handleLogin}
        >
          { translate('ra.auth.sign_in') }
        </Button>
      </CardContent>
    </StyledForm>
  )
}

const PREFIX = 'RaLoginForm'

export const LoginFormClasses = {
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  icon: `${PREFIX}-icon`,
}

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 300,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}))
