import React from 'react'
import { Admin, Resource, CustomRoutes } from 'react-admin'
import { Route, BrowserRouter } from 'react-router-dom'
import { QueryClient } from 'react-query'

import dataProvider from './utils/dataProvider'
import authProvider from './utils/authProvider'

import { TenantList, TenantShow, TenantEdit } from './views/Tenant'
import { AccountList, AccountShow, AccountEdit } from './views/Account'
import { UserList, UserShow, UserEdit } from './views/User'
import { PolicyList, PolicyShow, PolicyEdit } from './views/Policy'
import Logout from './views/Logout'
import Profile from './views/Profile'
import { Login } from './views/Login'
import Layout from './components/Layout'

import Dashboard from './views/Dashboard'
import UserIcon from '@mui/icons-material/People'

import { AppContextProvider } from './utils/appContext'
import theme from './components/Theme'

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 5 * 60 * 1000, // 5 minutes
      },
    },
  })

  return (
    <AppContextProvider>
      <BrowserRouter>
        <Admin
          authProvider={authProvider}
          dashboard={Dashboard}
          dataProvider={dataProvider}
          queryClient={queryClient}
          loginPage={<Login backgroundImage="/aserto-brandmark-color.svg" />}
          layout={Layout}
          theme={theme}
          requireAuth
        >
          <Resource name="users" list={UserList} show={UserShow} edit={UserEdit} icon={UserIcon} />
          <Resource name="tenants" list={TenantList} show={TenantShow} edit={TenantEdit} />
          <Resource name="accounts" list={AccountList} show={AccountShow} edit={AccountEdit} />
          <Resource name="policies" list={PolicyList} show={PolicyShow} edit={PolicyEdit} />
          <CustomRoutes>
            <Route path="/profile" element={<Profile />} />
            <Route path="/logout" element={<Logout />} />
          </CustomRoutes>
        </Admin>
      </BrowserRouter>
    </AppContextProvider>
  )
}

export default App
