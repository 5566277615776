import { fetchUtils } from 'react-admin'
import { stringify } from 'query-string'
import authConfig from './authConfig'
import { getToken } from './token'
import featureFlags from './featureFlags.json'

const apiUrl = `${authConfig.serviceUrl}/api/v1`
const httpClient = fetchUtils.fetchJson

async function options(tenantId) {
  const jwt = getToken()
  const token = jwt && jwt.id_token

  const returnVal = {
    user: {
      authenticated: true,
      token: `Bearer ${token}`,
    },
  }
  if (tenantId) {
    returnVal.headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'aserto-tenant-id': tenantId,
    })
  }
  return returnVal
}

function addPropertiesToUser(json) {
  const properties = json.properties
  for (const [key, value] of Object.entries(properties)) {
    json[key] = value
  }
  json.deleted = !json.properties['enabled']
  const ffProperties = properties['featureFlags']
  json.featureFlags = featureFlags.map((f) => {
    const value = (f.name && ffProperties && ffProperties[f.name]) || f.default
    return { ...f, value }
  })
}

const dataProvider = {
  getList: async (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
    }

    const url = `${apiUrl}/${resource}?${stringify(query)}`
    const opts = await options()

    return httpClient(url, opts).then(({ headers, json }) => {
      let users = json?.results
      if (resource === 'users') {
        users = users.map((u) => {
          addPropertiesToUser(u)
          return u
        })
      }
      return {
        data: users,
        total: json?.total,
      }
    })
  },

  getOne: async (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`
    const opts = await options()

    return httpClient(url, opts).then(({ json }) => {
      if (resource === 'users') {
        addPropertiesToUser(json?.result)
      }
      return {
        data: json?.result,
      }
    })
  },

  getMany: async (resource, params) => {
    const ids = params.ids.map((o) => (o.id ? o.id : o))
    const query = {
      filter: JSON.stringify({ id: ids }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    const opts = await options()

    return httpClient(url, opts).then(({ json }) => ({ data: json?.results }))
  },

  getManyReference: async (resource, params) => {
    const url = `${apiUrl}/${resource}`
    const opts = await options(params.id)

    return httpClient(url, opts).then(({ json }) => ({
      data: json?.results,
      total: json?.total,
    }))
  },

  update: async (resource, params) => {
    const opts = await options()
    opts.method = 'PUT'
    opts.body = JSON.stringify(params.data)

    return httpClient(`${apiUrl}/${resource}/${params.id}`, opts).then(({ json }) => {
      if (resource === 'users') {
        addPropertiesToUser(json?.result)
      }
      return {
        data: json?.result,
      }
    })
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
  },
}

export default dataProvider
