import { useEffect } from 'react'

const LogoutPage = () => {
  useEffect(() => {
    localStorage.removeItem('token')
  }, [/* logout */])
  return null
}

export default LogoutPage
