import React from 'react'
import { DashboardMenuItem, Menu, MenuItemLink } from 'react-admin'
import { Book, People, Label } from '@mui/icons-material'

const MyMenu = (props) => (
  <Menu {...props}>
    <DashboardMenuItem />
    <MenuItemLink to="/users" primaryText="Users" leftIcon={<People />} />
    <MenuItemLink to="/tenants" primaryText="Tenants" leftIcon={<Book />} />
    <MenuItemLink to="/accounts" primaryText="Accounts" leftIcon={<Label />} />
  </Menu>
)

export default MyMenu
